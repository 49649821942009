@font-face {
    font-family: "Helvetica Medium";
    src: url("../fonts/HelveticaNeueLTStd-Md.otf");
    font-display: swap;
    }
    @font-face {
    font-family: "Helvetica Normal";
    src: url("../fonts/HelveticaNeueLTStd-Lt.otf");
    font-display: swap;
    }
    @font-face {
    font-family: "Helvetica Bold";
    src: url("../fonts/HelveticaNeueLTStd-Bd.otf");
    font-display: swap;
    }
    @font-face {
    font-family: "Helvetica Heavy";
    src: url("../fonts/HelveticaNeueLTStd-Hv.otf");
    font-display: swap;
    }
        
    
    * {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
    }
    body{
    font-family: 'Helvetica Medium', sans-serif;
    margin: 0 auto;
    width: 100%;
    min-width: 330px;
        picture{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    .responsiva{
        width: 100%;
        max-width: 100%;
        height: auto;
    }
    
    #root{
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
    }
    .App{
        width: 100%;
        max-width: 1920px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-start;
        position: relative;
        .header{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            position: relative;
            .text{
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.20);
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-content: center;
                z-index: 1;
                .min{
                    width: 100%;
                    text-align: center;
                    font-size: 4.5rem;
                    color: #ffffff;
                }
                .logo{
                    width: 100%;
                    max-width: 580px;
                }
                .links{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    padding-top: 20px;
                    .link{
                        text-align: center;
                        width: 250px;
                        padding: 15px 20px;
                        color: #ffffff;
                        font-size: 3rem;
                        text-decoration: none;
                       border-right: 2px solid #ffffff;
                    }
                    .link:last-child{
                        border: 0px;
                    }
                }
            }
        }
        .separador{
            width: 100%;
            height: 90px;
            font-size: 2.5rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            background-color: #0097AA;
            font-family: 'Helvetica Heavy', sans-serif;
        }
        .grid{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: flex-start;
            .item{
                width: 50%;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                .text{
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.40);
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-content: center;
                    color: #ffffff;
                    .title{
                        width: 100%;
                        font-size: 5rem;
                        margin: 20px 0;
                        text-align: center;
                        font-family: 'Helvetica Heavy', sans-serif;
                    }
                    p{
                        width: 100%;
                        font-size: 2rem;
                        text-align: center;
                    }
                    ul{
                        width: 100%;
                        max-width: 600px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        li{
                            width: 100%;
                            text-align: left;
                            font-size: 2rem;
                        }
                    }
                    .cont-button{
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        padding: 20px;
                        .btn-app{
                            font-family: 'Helvetica Heavy', sans-serif;
                            background-color: rgba(0, 150, 170, 0.50);
                            width: 100%;
                            max-width: 450px;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            padding: 20px;
                            font-size: 1.8rem;
                            color: #ffffff;
                            text-decoration: none;
                            border: 1px solid #ffffff;
                        }
                    }
                }
            }
        }
        .grid-piscinas{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: flex-start;
            position: relative;
            hr{
                position: absolute;
                z-index: 2;
                left: 50%;
                top: 3%;
                border:#FFFFFF solid;
                border-width: 1px;
                opacity: 50%;
                height: 90%;
            }
            .item{
                width: 50%;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                .text{
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.40);
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-content: center;
                    color: #ffffff;
                    .title{
                        width: 95%;
                        font-size: 3.8rem;
                        margin: 0;
                        text-align: center;
                        font-family: 'Helvetica Heavy', sans-serif;
                    }
                    p{
                        width: 100%;
                        font-size: 1.6rem;
                        text-align: center;
                        margin: 0;
                    }
                    .espacio {
                        height: 40%;
                    }
                    ul{
                        width: 100%;
                        max-width: 600px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        li{
                            width: 80%;
                            text-align: left;
                            font-size: 1.6rem;
                        }
                    }
                    .cont-button{
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        padding: 20px;
                        .btn-app{
                            font-family: 'Helvetica Heavy', sans-serif;
                            background-color: rgba(0, 150, 170, 0.50);
                            width: 100%;
                            max-width: 450px;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            padding: 20px;
                            font-size: 1.7rem;
                            color: #ffffff;
                            text-decoration: none;
                            border: 1px solid #ffffff;
                        }
                    }
                }
            }
        }
        .separador.footer{
            height: 200px;
            align-content: flex-start;
        }
        .footer-app{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: flex-end;
            background-color: #ffffff;
            height: 200px;
            color: #333333;
            font-size: 1.4rem;
            position: relative;
            font-family: 'Helvetica Normal', sans-serif;
            .revi-widget{
                position: absolute;
                z-index: 1;
                width: 100%;
                max-width: 500px;
                top: -25%;
            }
        }
    }
    
    .loader{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        z-index: 99999;
        background-color: #0097AA;
        overflow-y: hidden;
        .intex{
            width: 100%;
            max-width: 500;
            display: flex;
            flex-wrap: wrap;
            justify-content:center;
            align-items: center;
            position: relative;
            .letra{
                width: 20%;
                max-width: 100px;
                animation: intex 1.7s infinite alternate;
                @for $i from 0 through 5 {
                  &:nth-child(#{$i+1}) {
                    animation-delay: #{$i*.1}s;
                  }
                }
            }
        }
        
    }
    @keyframes intex {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    /*.lds-ripple {
        display: inline-block;
        position: relative;
        width: 150px;
        height: 150px;
    
    }
    .lds-ripple div {
    position: absolute;
    border: 4px solid rgb(95, 95, 95);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    
    .lds-ripple div:nth-child(2) {
    animation-delay: -0.8s;
    }
    @keyframes lds-ripple {
    0% {
        top: 60px;
        left: 60px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 120px;
        height: 120px;
        opacity: 0;
    }
    }*/
    
    @media screen and (max-width: 1480px) {
        .App{
            .header{
                .text{
                    .logo{
                        max-width: 500px;
                    }
                    .min{
                        font-size: 3.5rem;
                    }
                    .links{
                        .link{
                            font-size: 2.6rem;
                        }
                    }
                }
            }
            .separador{
                font-size: 2.4rem;
            }
            .grid{
                .item{
                    .text{
                        .title{
                            font-size: 3.2rem;
                            margin: 15px 0px;
                        }
                        p{
                            margin: 10px 0px;
                            font-size: 1.8rem;
                        }
                        ul{
                            width: 500px;
                            li{
                                font-size: 1.8rem;
                            }
                        }
                    }
                }
            }
            .grid-piscinas{
                .item{
                    .text{
                        .title{
                            font-size: 3rem;
                            margin: 0;
                        }
                        p{
                            margin: 10px 0px;
                            font-size: 1.5rem;
                        }
                        .espacio{
                            height: 35%;
                        }
                        ul{
                            width: 500px;
                            li{
                                font-size: 1.5rem;
                                width: 100%;
                            }
                        }
                        .cont-button{
                            padding: 10px;
                        }
                    }
                }
            }
            .separador.footer{
                height: 180px;
            }
            .footer-app{
                height: 180px;
                .revi-widget{
                    max-width: 400px;
                }
            }
        }
    }
    
    @media screen and (max-width: 1280px) {
        .App{
            .header{
                .text{
                    .logo{
                        max-width: 400px;
                    }
                    .min{
                        font-size: 3rem;
                    }
                    .links{
                        .link{
                            font-size: 2.2rem;
                        }
                    }
                }
            }
            .separador{
                font-size: 2.4rem;
            }
            .grid{
                .item{
                    .text{
                        .title{
                            font-size: 3rem;
                            margin: 15px 0px;
                        }
                        p{
                            margin: 10px 0px;
                            font-size: 1.5rem;
                        }
                        ul{
                            width: 500px;
                            li{
                                font-size: 1.5rem;
                            }
                        }
                        .cont-button{
                            .btn-app{
                                padding: 15px;
                                font-size: 1.5rem;
                            }
                        }
                    }
                }
            }
            .grid-piscinas{
                .item{
                    .text{
                        .title{
                            font-size: 2.5rem;
                            margin: 0;
                        }
                        p{
                            margin: 10px 0px;
                            font-size: 1.2rem;
                        }
                        .espacio{
                            height: 35%
                        }
                        ul{
                            width: 500px;
                            li{
                                font-size: 1.2rem;
                                width: 100%;
                            }
                        }
                        .cont-button{
                            padding: 5px;
                            .btn-app{
                                padding: 15px;
                                font-size: 1.5rem;
                            }
                        }
                    }
                }
            }
            .separador.footer{
                height: 180px;
            }
            .footer-app{
                height: 180px;
                .revi-widget{
                    max-width: 400px;
                }
            }
        }
    }
    
    @media screen and (max-width: 1080px) {
        .App{
            .header{
                .text{
                    .logo{
                        max-width: 400px;
                    }
                    .min{
                        font-size: 2.5rem;
                    }
                }
            }
            .separador{
                font-size: 2rem;
            }
            .grid{
                .item{
                    .text{
                        .title{
                            font-size: 2.5rem;
                            margin: 15px 0px;
                        }
                        p{
                            font-size: 1.3rem;
                            margin: 10px 0px;
                        }
                        ul{
                            li{
                                font-size: 1.1rem;
                            }
                        }
                        .cont-button{
                            .btn-app{
                                padding: 15px;
                                max-width: 350px;
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
            }
            .grid-piscinas{
                .item{
                    .text{
                        .title{
                            font-size: 2rem;
                            margin: 0;
                        }
                        p{
                            font-size: 1rem;
                            margin: 10px 0px;
                        }
                        .espacio{
                            height: 40%;
                        }
                        ul{
                            width: 500px;
                            li{
                                font-size: 1.1rem;
                                width: 80%;
                            }
                        }
                        .cont-button{
                            padding: 0;
                            .btn-app{
                                padding: 15px;
                                max-width: 350px;
                                font-size: 1.2rem;
                            }
                        }
                    }
                }
            }
            .separador.footer{
                height: 150px;
            }
            .footer-app{
                height: 150px;
                font-size: 1.2rem;
                .revi-widget{
                    max-width: 350px;
                }
            }
        }
    }
    
    @media screen and (max-width: 940px) {
        .App{
            .header{
                .text{
                    .logo{
                        max-width: 300px;
                    }
                    .min{
                        font-size: 2.2rem;
                        margin: 15px 0px;
                    }
                    .links{
                        .link{
                            font-size: 1.8rem;
                            padding: 10px;
                        }
                    }
                }
            }
            .grid{
                .item{
                    width: 100%;
                    .text{
                        .title{
                            font-size: 3rem;
                            margin: 20px 0px;
                        }
                        p{
                            font-size: 1.5rem;
                            margin: 20px 0px;
                        }
                        ul{
                            li{
                                font-size: 1.4rem;
                            }
                        }
                        .cont-button{
                            .btn-app{
                                padding: 20px;
                                max-width: 350px;
                                font-size: 1.6rem;
                            }
                        }
                    }
                }
            }
            .grid-piscinas{
                hr{
                    display: none;
                }
                .item{
                    width: 100%;
                    .text{
                        .title{
                            font-size: 3rem;
                            margin: 0;
                        }
                        p{
                            font-size: 1.5rem;
                            margin: 20px 0px;
                        }
                        .espacio{
                            height: 30%
                        }
                        ul{
                            max-width: 500px;
                            li{
                                font-size: 1.4rem;
                            }
                        }
                        .cont-button{
                            .btn-app{
                                padding: 20px;
                                max-width: 350px;
                                font-size: 1.6rem;
                            }
                        }
                    }
                }
            }
            .footer-app{
                height: 180px;
                .revi-widget{
                    max-width: 100%;
                }
            }
        }
    }
    
    @media screen and (max-width: 600px){
        .App{
            .header{
                .text{
                    .min{
                        font-size: 2rem;
                        margin: 10px 0px;
                    }
                    .logo{
                        max-width: 200px;
                    }
                   .links{
                       padding-top: 10px;
                       .link{
                           width: 200px;
                        font-size: 1.5rem;
                       }
                       
                   } 
                }
            }
            .separador{
                font-size: 1.3rem;
                height: 60px; 
            }
            .grid{
                .item{
                    .text{
                        .title{
                            font-size: 2rem;
                            margin: 10px 0px;
                        }
                        p{
                            font-size: 1.2rem;
                            margin: 10px 0px;
                        }
                        ul{
                            li{
                                font-size: 1rem;
                            }
                        }
                        .cont-button{
                            padding: 10px;
                            .btn-app{
                                padding: 10px;
                                font-size: 1.3rem;
                                max-width: 300px;
                            }
                        }
                    }
                }
            }
            .grid-piscinas{
                hr{
                    display: none;
                }
                .item{
                    .text{
                        .title{
                            font-size: 2rem;
                            margin: 0;
                        }
                        p{
                            font-size: 1.2rem;
                            margin: 10px 0px;
                        }
                        .espacio{
                            height: 35%;
                        }
                        ul{
                            max-width: 430px;
                            li{
                                font-size: 1rem;
                            }
                        }
                        .cont-button{
                            padding: 10px;
                            .btn-app{
                                padding: 10px;
                                font-size: 1.3rem;
                                max-width: 300px;
                            }
                        }
                    }
                }
            }
            .separador.footer{
                font-size: 1.3rem;
                height: 120px;
            }
            .footer-app{
                height: 180px;
                font-size: 1rem;
                .revi-widget{
                    max-width: 100%;
                }
            }
        }
    }
    
    @media screen and (max-width: 420px){
        .App{
            .header{
                .text{
                    .min{
                        font-size: 1.5rem;
                        margin: 10px 0px;
                    }
                    .logo{
                        max-width: 150px;
                    }
                   .links{
                       padding-top: 10px;
                       .link{
                           width: 150px;
                           font-size: 1.2rem;
                           padding: 5px;
                       }
                       
                   } 
                }
            }
            .separador{
                font-size: 1rem;
            }
            .grid{
                .item{
                    .text{
                        .title{
                            font-size: 1.5rem;
                            margin: 10px 0px;
                        }
                        p{
                            font-size: 1rem;
                            margin: 10px 0px;
                        }
                        ul{
                            margin: 10px 0px;
                            li{
                                font-size: 0.9rem;
                            }
                        }
                        .cont-button{
                            padding: 10px;
                            .btn-app{
                                padding: 10px;
                                font-size: 1rem;
                                max-width: 250px;
                            }
                        }
                    }
                }
            }
            .grid-piscinas{
                hr{
                    display: none;
                }
                .item{
                    .text{
                        width: 100%;
                        .title{
                            font-size: 1.5rem;
                            margin: 0;
                        }
                        p{
                            font-size: 1rem;
                            margin: 10px 0px;
                        }
                        .espacio{
                            height: 10%;
                        }
                        ul{
                            margin: 10px 0px;
                            max-width: 370px;
                            li{
                                font-size: 0.9rem;
                            }
                        }
                        .cont-button{
                            padding: 10px;
                            .btn-app{
                                padding: 10px;
                                font-size: 1rem;
                                max-width: 250px;
                            }
                        }
                    }
                }
            }
            .separador.footer{
                font-size: 1.2rem;
                height: 100px;
            }
            .footer-app{
                height: 180px;
                .revi-widget{
                    max-width: 100%;
                }
            }
        }
    }